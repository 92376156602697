import axios from "axios";

export const loginWithOtp = async (
  otp,
  mobile,
  transaction_id,
  latitude,
  longitude
) => {
  try {
    // Send OTP verification request to the backend
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}api/vouchers/verify-otp`,
      {
        mobile,
        otp,
        transaction_id,
        latitude,
        longitude,
      }
    );
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
      console.log("Token saved:", response.data.token);

      return response.data;
    } else {
      throw new Error("Token not found in the response");
    }
  } catch (err) {
    // Log the error message and return it
    console.error("Error in OTP verification:", err.message);
    return err.message; // Return the error message
  }
};
