import axios from "axios";

export const getCoupons = async (currentPage) => {
  try {
    // Retrieve and parse data from sessionStorage
    const apiResponse = JSON.parse(sessionStorage.getItem("apiResponse"));
    if (!apiResponse || !apiResponse.userData) {
      throw new Error("Invalid or missing sessionStorage data");
    }

    const { amount, mobile, almond_transaction_id } = apiResponse.userData;

    // Make the API request with token in headers
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}api/vouchers/fetch-voucher`,
      {
        amount,
        mobile,
        transaction_id: almond_transaction_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (err) {
    console.error("Error fetching coupons:", err.message);
    return err.message;
  }
};
