import axios from "axios";

export const RedeemCoupon = async (coupon_id, coupon_code) => {
  console.log("name2", coupon_id);
  const token = localStorage.getItem("token");
  try {
    if (!coupon_id || !coupon_code) {
      throw new Error("Values are invalid");
    }
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}api/user/almond/coupancardcreate`,
      { coupon_id: coupon_id, coupon_code: coupon_code },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (err) {
    return err.message;
  }
};
